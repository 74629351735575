/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import bgImage from "../assets/img/bg.png";
import Wallet3 from "./Wallet3";
import share_logo from "../assets/wallet/share.png";
import { FaXTwitter, FaTelegram } from "react-icons/fa6";
import { SiSolana } from "react-icons/si";
import { toast } from "react-toastify";
import ShareReferralModal from "./ShareReferralModal";
import { useAtom } from "jotai";
import { invitesLeftAtom, walletAddressAtom, isAuthenticatedAtom, telegramUserDataAtom, telegramWebAppAtom, telegramInitDataAtom, userAtom, coreAtom, roshamboActorAtom, questAtom, commissionAtom, disabledButtonsAtom } from "../store/Atoms";
import analytics from "../utils/segment";
import { useNavigate } from "react-router-dom";
import eyeroll from "../assets/img/eyeroll.png";
import axios from "axios";
import SpinRewardModal from "./Roshambo/QuestRewardModal";
import EyeRoll from "./Roshambo/EyeRoll";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
      delayChildren: 0.2,
    },
  },
};

const QuestV2 = () => {
  const [telegramUserData] = useAtom(telegramUserDataAtom);
  const [telegram] = useAtom(telegramWebAppAtom);
  const [user] = useAtom(userAtom);
  const [invitesLeft] = useAtom(invitesLeftAtom);
  const [isAuthenticated] = useAtom(isAuthenticatedAtom);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [coreAgent] = useAtom(coreAtom);
  const [roshamboAgent] = useAtom(roshamboActorAtom);
  const [initData] = useAtom(telegramInitDataAtom);
  const [walletAddress] = useAtom(walletAddressAtom);

  // mocking data
  const [friendsPlaying, setFriendsPlaying] = useState(16);
  const [roundPlayed, setRoundPlayed] = useState(146);
  const [totalEarnedEyes, setTotalEarnedEyes] = useState(1500);
  const [totalEarnedSol, setTotalEarnedSol] = useState(0.069589);
  const [joinedTelegramGrup, setJoinedTelegramGrup] = useState(false);
  const [subscribeTelegramAnnouncement, setSubscribeTelegramAnnouncement] = useState(false);
  const [followX, setfollowX] = useState(false);
  const [questRewardMessage, setQRM] = useState("");
  const [addIconToUsername, setAddIconToUsername] = useState(false);
  const [play25xWeekly, setplay25xWeekly] = useState(false);
  const [play5xstreakMode, setPlay5xstreakMode] = useState(false);
  const [topUpMin1Sol, setTopUpMin1Sol] = useState(false);
  const [airdropReq, setAirdropReq] = useState(false);
  const [showAirdropReq, setShowAirdropReq] = useState(false);
  const [freeMode, setFreeMode] = useState(false);
  const [dailyCheckin, setDailyCheckin] = useState(false);
  const [questData, setQuestData] = useAtom(questAtom);
  const [commissiondata, setCommissionData] = useAtom(commissionAtom);
  const [buttons, setButton] = useState({
    jointelegram: "Claim",
    useUniqueName: "Claim",
    play25x: "Claim",
    play5streak: "Claim",
    dailyCheckin: "Claim",
  });
  const [showEyesTokenModal, setShowEyesTokenModal] = useState(false);
  const [eyesGet, setEyesGet] = useState(0);
  const [isEyeRollOpen, setIsEyeRollOpen] = useState(false);
  const [dailySpin, setDailySping] = useState(false);

  // Tambahkan state baru di bagian atas komponen QuestV2
  const [disabledButtons, setDisabledButtons] = useAtom(disabledButtonsAtom);

  // mock function

  const handleAction = async (key) => {
    console.log(`action ${key} trigered"`);
    var n = await checkTelegramMembership();
  };
  const [checkGroupButton, setCheckGroupButton] = useState("Join");
  const handleCheckUsernameAndGroup = async (eyesReward) => {
    setDisabledButtons((prev) => ({ ...prev, telegramGroup: true }));
    setCheckGroupButton("Claiming...");

    try {
      var n = await checkTelegramMembership();
      const { first_name, id } = telegramUserData;
      analytics.track("Quest - User Change Telegram Name", {
        user_id: id,
        name: first_name,
        game_name: user?.userName,
        label: "quest",
      });
    } catch (error) {
      // Handle error jika perlu
      setDisabledButtons((prev) => ({ ...prev, telegramGroup: false }));
    }

    setCheckGroupButton("Claim");
    setEyesGet(eyesReward);
  };
  const [airdropButton, setAirdropButton] = useState("Claim");
  const handleAirdropRequirement = async (eyesReward) => {
    setDisabledButtons((prev) => ({ ...prev, airdrop: true }));
    setAirdropButton("Claiming...");

    try {
      var n = await coreAgent.completeAirdropRequirement();
      if (n.success) {
        setAirdropReq(true);
        setEyesGet(eyesReward);
        const { first_name, id } = telegramUserData;
        analytics.track("Quest - User Complete Airdrop Tasks", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
        });
        setQRM("You have successfully completed the task, 0.005 SOL is yours!");
        setShowEyesTokenModal(true);
      } else if (n.failed) {
        setDisabledButtons((prev) => ({ ...prev, airdrop: false }));
        const { first_name, id } = telegramUserData;
        analytics.track("Quest Failed - Complete Airdrop Tasks", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
          reason: n.failed,
        });
        toast.error(n.failed, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("other", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e) {
      setDisabledButtons((prev) => ({ ...prev, airdrop: false }));
      toast.error(e, {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    setAirdropButton("Claim");
    setEyesGet(eyesReward);
  };

  const [freemodeButton, setFreeModeButton] = useState("Claim");
  const handleFreeModeClaim = async (eyesReward) => {
    setDisabledButtons((prev) => ({ ...prev, freeMode: true }));
    setFreeModeButton("Claiming...");

    try {
      var n = await coreAgent.completeDailyFreePlay();
      if (n.success) {
        setFreeMode(true);
        setEyesGet(eyesReward);
        const { first_name, id } = telegramUserData;
        analytics.track("Quest - User Play 10x Free Mode", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
        });
        var msg = "You have successfully completed the task, " + eyesReward + " EYES is yours!";
        setQRM(msg);
        setShowEyesTokenModal(true);
      } else if (n.failed) {
        setDisabledButtons((prev) => ({ ...prev, freeMode: false }));
        const { first_name, id } = telegramUserData;
        toast.error(n.failed, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        analytics.track("Quest Failed - User Play 10x Free Mode", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
          reason: n.failed,
        });
      }
    } catch (e) {
      setDisabledButtons((prev) => ({ ...prev, freeMode: false }));
    }

    setFreeModeButton("Claim");
    setEyesGet(eyesReward);
  };

  const [dailyButton, setDailyButton] = useState("Claim");
  const handleDailyCheckin = async (eyesReward) => {
    setDisabledButtons((prev) => ({ ...prev, dailyCheckin: true }));
    setDailyButton("Claiming...");

    try {
      var n = await coreAgent.completeDailyCheckinTask();
      if (n.success) {
        setDailyCheckin(true);
        setEyesGet(eyesReward);
        var msg = "You have successfully completed the task, " + eyesReward + " EYES is yours!";
        setQRM(msg);
        setShowEyesTokenModal(true);
      } else if (n.failed) {
        setDisabledButtons((prev) => ({ ...prev, dailyCheckin: false }));
        const { first_name, id } = telegramUserData;
        toast.error(n.failed, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e) {
      setDisabledButtons((prev) => ({ ...prev, dailyCheckin: false }));
    }

    setDailyButton("Claim");
    setEyesGet(eyesReward);
  };

  async function questFetch() {
    //
  }

  const [weeklyPlayButton, setWPButton] = useState("Claim");
  const handleWeeklyPlay = async (eyesReward) => {
    setDisabledButtons((prev) => ({ ...prev, weeklyPlay: true }));
    setWPButton("Claiming...");

    try {
      var n = await coreAgent.completeWeeklyRoshamboPlayTask();
      if (n.success) {
        const { first_name, id } = telegramUserData;
        analytics.track("Quest - Play 25x Per Week", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
        });
        setplay25xWeekly(true);
        setEyesGet(eyesReward);
        var msg = "You have successfully completed the task, " + eyesReward + " EYES is yours!";
        setQRM(msg);
        setShowEyesTokenModal(true);
      } else if (n.failed) {
        setDisabledButtons((prev) => ({ ...prev, weeklyPlay: false }));
        const { first_name, id } = telegramUserData;
        analytics.track("Quest Failed - Play 25x Per Week", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
          reason: n.failed,
        });
        toast.error(n.failed, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e) {
      setDisabledButtons((prev) => ({ ...prev, weeklyPlay: false }));
    }

    setWPButton("Claim");
    setEyesGet(eyesReward);
  };
  const [weeklyStreakButton, setWSButton] = useState("Claim");
  const handleWeeklyStreakPlay = async (eyesReward) => {
    setDisabledButtons((prev) => ({ ...prev, weeklyStreak: true }));
    setWSButton("Claiming...");

    try {
      var n = await coreAgent.completeWeeklyStreakPlayTask();
      if (n.success) {
        const { first_name, id } = telegramUserData;
        analytics.track("Quest - User Play 5x Streak Per Week", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
        });
        setPlay5xstreakMode(true);
        setEyesGet(eyesReward);
        var msg = "You have successfully completed the task, " + eyesReward + " EYES is yours!";
        setQRM(msg);
        setShowEyesTokenModal(true);
      } else if (n.failed) {
        setDisabledButtons((prev) => ({ ...prev, weeklyStreak: false }));
        const { first_name, id } = telegramUserData;
        analytics.track("Quest Failed - Play 5x Streak Per Week", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
          reason: n.failed,
        });
        toast.error(n.failed, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e) {
      setDisabledButtons((prev) => ({ ...prev, weeklyStreak: false }));
    }

    setWSButton("Claim");
    setEyesGet(eyesReward);
  };
  const [unameButton, setUnameButton] = useState("Claim");
  const handleUname = async (eyesReward) => {
    var st = buttons;
    st.useUniqueName = "Claiming..";
    setUnameButton("Claiming...");
    setButton(st);

    var n = await checkTelegramMembership();
    st.useUniqueName = "Claim";
    setUnameButton("Claim");
    setButton(st);
    setEyesGet(eyesReward);
  };

  const [topupButtom, setTopupButton] = useState("Claim");
  const handleTopup = async (eyesReward) => {
    setDisabledButtons((prev) => ({ ...prev, topup: true }));
    setTopupButton("Claiming...");

    try {
      var n = await coreAgent.completeSOLTopUpTask();
      if (n.success) {
        const { first_name, id } = telegramUserData;
        analytics.track("Quest - User Topped Up Min 1 SOL", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
        });
        setTopUpMin1Sol(true);
        setEyesGet(eyesReward);
        var msg = "You have successfully completed the task, " + eyesReward + " EYES is yours!";
        setQRM(msg);
        setShowEyesTokenModal(true);
      } else if (n.failed) {
        setDisabledButtons((prev) => ({ ...prev, topup: false }));
        const { first_name, id } = telegramUserData;
        analytics.track("Quest Failed - Topping up min 1 SOL", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
          reason: n.failed,
        });
        toast.error(n.failed, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (e) {
      setDisabledButtons((prev) => ({ ...prev, topup: false }));
    }

    setTopupButton("Claim");
    setEyesGet(eyesReward);
  };

  const [followButton, setFollow] = useState("Follow");
  const handleFollowX = async (eyesReward) => {
    setDisabledButtons((prev) => ({ ...prev, followX: true }));
    setFollow("Claiming...");

    try {
      var n = await coreAgent.completeFollowX();
      if (n.success) {
        const { first_name, id } = telegramUserData;
        analytics.track("Quest - User Follow X", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
        });
        setfollowX(true);
        setEyesGet(eyesReward);
        var msg = "You have successfully completed the task, " + eyesReward + " EYES is yours!";
        setQRM(msg);
        setShowEyesTokenModal(true);
      } else if (n.failed) {
        // Handle failure
        setDisabledButtons((prev) => ({ ...prev, followX: false }));
        // ... kode error handling yang sudah ada
      }
    } catch (error) {
      setDisabledButtons((prev) => ({ ...prev, followX: false }));
    }

    setFollow("Claim");
    setEyesGet(eyesReward);
  };

  const referralCode = user.referralCode;
  const navigate = useNavigate();

  const BOT_TOKEN = "7454958165:AAEZY04lOIKr67_Cq9j23pWntXBApePPjqM";
  const CHAT_ID = "@your_group_username"; // Ganti dengan chat_id yang benar

  const checkTelegramMembership = async () => {
    if (isAuthenticated) {
      let url = "https://api.dragoneyes.xyz/dragontelegram/api/groupCheck";
      if (initData) {
        var param = Object.fromEntries(new URLSearchParams(initData));
        param.principal = walletAddress;
        param.initData = Object.fromEntries(new URLSearchParams(initData));
        param.first_name = telegram.initDataUnsafe.user.first_name;
        if (param.first_name == "" || param.first_name === undefined) param.first_name = "none";
        param.user_id = telegram.initDataUnsafe.user.id;
        param.last_name = telegram.initDataUnsafe.user.last_name;
        if (param.last_name == "" || param.last_name === undefined) param.last_name = "none";
        param.username = telegram.initDataUnsafe.user.username;
        var additionalParam = { principal: walletAddress };
        var allparam = { ...param, ...additionalParam };
        try {
          const response = await axios.post(url, param, {
            headers: {
              "Content-Type": "application/json", // Optional headers
            },
          });

          console.log(response, "<<<< group check");
          if (response.data.isMember == "yes") {
            setJoinedTelegramGrup(true);
          }
          if (response.data.isUsernameUnique == "yes") {
            setAddIconToUsername(true);
          }

          // Handle success
        } catch (error) {
          console.log("check telegram error");
          //
        }
      }
    }
  };

  const handleShareClose = () => {
    setIsShareModalOpen(false);
  };

  const shareReferralCode = () => {
    if (telegramUserData) {
      const { first_name, id } = telegramUserData;
      analytics.track("User Shared Referral Code", {
        user_id: id,
        name: first_name,
        game_name: user?.userName,
        user_referral_code: referralCode,
        label: "share",
      });
    }
    if (telegram) {
      const tgAppLink = `t.me/dragoneyesxyz_bot/roshambo?startapp=${referralCode}`;
      var message = encodeURIComponent(`Claim your 0.005 SOL airdrop NOW by opening this Roshambo Telegram App ${tgAppLink} before expired!`);
      if (invitesLeft <= 0) {
        message = encodeURIComponent(`Check this trending Rock Scissor Paper game where you can 20x your bet in Solana! Get airdrop by doing tasks and play the game!`);
      }
      const url = `https://t.me/share/url?url=${message}`;
      telegram.openTelegramLink(url);
      handleShareClose();
    } else {
      console.log("Telegram WebApp is not available or user is not authenticated");
    }
  };

  useEffect(() => {
    //checkTelegramMembership();

    async function questFetch() {
      // console.log(questData, "<<<<<<asdsad");
      var taskList = questData.taskHash;
      var completedTask = questData.completedTaskHash;
      var dtaskList = questData.dailyTaskHash;
      var dcompletedTask = questData.completedDailyTaskHash;
      if (Number(questData.pendingAirdrop) == 1) {
        setShowAirdropReq(true);
      }
      var wtaskList = questData.weeklyTaskHash;
      var wcompletedTask = questData.completedWeeklyTaskHash;
      // console.log(wcompletedTask, "<<<<<<asdsad");
      taskList.forEach((task) => {
        var name = task[0];
        //console.log(name, "<<<<<<<<<< taskname");
        //if (task[0][0] == "telegramgroup") {
        completedTask.forEach((completed) => {
          if (completed == "telegramgroup" && completed == name) {
            setJoinedTelegramGrup(true);
          }
          if (completed == "uniqueUsername" && completed == name) {
            setAddIconToUsername(true);
          }

          if (completed == "followX" && completed == name) {
            setfollowX(true);
          }
          if (completed == "airdropRequirement" && completed == name) {
            setAirdropReq(true);
          }
        });
      });
      if (wcompletedTask.length)
        wtaskList.forEach((task) => {
          var name = task[0];

          wcompletedTask.forEach((completed) => {
            if (completed == "play25x" && completed == name) {
              setplay25xWeekly(true);
            }
            if (completed == "play5streak" && completed == name) {
              setPlay5xstreakMode(true);
            }
            if (completed == "topup" && completed == name) {
              setTopUpMin1Sol(true);
            }
          });
        });
      if (dcompletedTask.length)
        dtaskList.forEach((task) => {
          var name = task[0];
          //console.log(name, "<<<<<<<<<< taskname daily");
          //if (task[0][0] == "telegramgroup") {
          dcompletedTask.forEach((completed) => {
            if (completed == "dailyCheckin" && completed == name) {
              setDailyCheckin(true);
            }
            if (completed == "play10free" && completed == name) {
              setFreeMode(true);
            }
            if (completed == "dailyspin" && completed == name) {
              setDailySping(true);
            }
          });
        });
    }
    if (questData && isAuthenticated) {
      questFetch();
    }
  }, [questData, commissiondata, isAuthenticated]);

  const handleLinkClick = (e) => {
    e.preventDefault();
    setFollow("Claim");

    window.Telegram.WebApp.openLink("https://x.com/intent/follow?screen_name=dragoneyesxyz");
  };

  const ambassadorLinkClick = (e) => {
    e.preventDefault();

    window.Telegram.WebApp.openLink("https://docs.google.com/forms/d/e/1FAIpQLSfXQ4XAqLV6Z_bhKUBPOvkWMJE2C07R9JQIKs53Ny0gBIanMQ/viewform");
  };

  const handleGroupLinkClick = (e) => {
    e.preventDefault();
    setCheckGroupButton("Claim");
    const { first_name, id } = telegramUserData;
    analytics.track("Quest - User Follow Telegram", {
      user_id: id,
      name: first_name,
      game_name: user?.userName,
      label: "quest",
    });
    window.Telegram.WebApp.openLink("https://t.me/HouseOfXDragon");
  };

  return (
    <div className="h-screen w-full relative overflow-hidden bg-[#2A2A2A] bg-cover bg-center">
      {isAuthenticated ? (
        questData && commissiondata ? (
          <div className="overflow-y-scroll max-h-full text-white p-6 no-scrollbar ">
            {/* Invite Section */}
            <motion.div
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                duration: 0.5,
                type: "spring",
                stiffness: 100,
                damping: 15,
              }}
            >
              <p className="font-passion text-white text-[20px] ">Invite</p>
              <div className="bg-[#221C15] px-6 py-5 rounded-3xl mb-6">
                <div className="flex justify-between items-center">
                  {invitesLeft ? (
                    <div className="flex flex-col w-1/2">
                      <div className="bg-[#103975] px-2 py-1 rounded-t">
                        <p className="text-white font-semibold text-[14px]">{invitesLeft} Airdrop invites left</p>
                      </div>
                      <div className="bg-[#474747] px-2 py-1 rounded-b">
                        <p className="text-white text-[9px]">Your friend will get 0.005 SOL</p>
                      </div>
                    </div>
                  ) : (
                    <div onClick={ambassadorLinkClick} className="cursor-pointer">
                      <div className="flex flex-col w-full bg-[#221C15] rounded-lg">
                        <div className="bg-[#103975] px-2 py-1 rounded-t-lg">
                          <p className="text-white font-bold text-[14px] animate-pulse">Join Ambassador Program</p>
                        </div>
                        <div className="bg-[#474747] px-2 py-1 rounded-b-lg text-center">
                          <p className="text-white text-[11px] font-semibold">Get 20% commission</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <button
                    className="bg-[#22C31F] text-black w-[70px] rounded-full"
                    onClick={() => {
                      setIsShareModalOpen(true);
                    }}
                  >
                    Invite
                  </button>
                </div>

                <div className="text-[11px] mt-2 text-[#22C31F] font-semibold">
                  <p>+10,000 EYES</p>
                  <p>+20% fee commission</p>
                </div>

                <div className="border-t-2 border-[#392F24] mt-2 mb-2"></div>

                <div className="flex justify-around items-center w-full">
                  <div className="flex gap-1.5 items-end">
                    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.5 13V5.5M6 13V1M1.5 13V8.5" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <p className="text-white text-xs font-bold">This week</p>
                  </div>

                  <div className="flex gap-1.5 items-center font-bold">
                    <p className="text-[#E8A700] text-[18px]">{Number(commissiondata.totalRoshamboFriendPlayed)}</p>
                    <p className="text-white text-[9px] w-12 break-words">Friends playing</p>
                  </div>

                  <div className="flex gap-1.5 items-center font-bold">
                    <p className="text-[#E8A700] text-[18px]">{Number(commissiondata.totalRoshamboPlayed)}</p>
                    <p className="text-white text-[9px]  w-12 break-words">Rounds played</p>
                  </div>
                </div>
                <div className="border-t-2 border-[#392F24] mt-2 mb-2"></div>

                <div className="flex justify-between items-center">
                  <p className="text-[#FEE489] font-passion text-[15px]">Total Earned</p>
                  <p className="text-[#FEE489] font-passion text-[15px]">{Number(questData.eyesReferralRewardTotal) / 1e8} EYES</p>
                  <p className="text-[#FEE489] font-passion text-[15px]">{Number(commissiondata.solRoshamboCommissionTotal) / 1e9} SOL</p>
                </div>
              </div>
            </motion.div>

            {/* Main Section */}
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.5,
                type: "spring",
                stiffness: 100,
                damping: 15,
              }}
            >
              {showAirdropReq && (
                <div>
                  <p className="font-passion text-white text-[20px]">Airdrop Claim</p>
                  <div className="bg-[#221C15] rounded-3xl mb-6 py-5 shadow-[0_0_9.5px_#E8A700C2]">
                    <div className="flex justify-between items-center px-6">
                      <div>
                        <p className={`${airdropReq ? "text-[#727272]" : "text-white"} text-[11px]`}>
                          Top up min 0.01 SOL to claim airdop
                          <br />
                        </p>
                        <p className={`${airdropReq ? "text-[#727272]" : "text-[#22C31F]"} text-[11px]`}>+0.005 SOL airdop</p>
                      </div>

                      {airdropReq ? (
                        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18 2L7 13L2 8" stroke="#22C31F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                      ) : (
                        <button
                          className={`bg-[#22C31F] text-black text-sm w-[70px] rounded-full ${disabledButtons.airdrop ? "opacity-50 cursor-not-allowed" : ""}`}
                          onClick={() => handleAirdropRequirement(2000)}
                          disabled={disabledButtons.airdrop}
                        >
                          {airdropButton}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </motion.div>

            {/* Daily Section */}
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.5,
                type: "spring",
                stiffness: 100,
                damping: 15,
              }}
            >
              <p className="font-passion text-white text-[20px]">Daily</p>
              <div className="bg-[#221C15] rounded-3xl mb-6 py-5">
                {/* Spin to win task */}
                <div className="flex justify-between items-center px-6 mb-4 relative">
                  <div>
                    <p className={`${dailySpin ? "text-[#727272]" : "text-white"} text-[11px]`}>
                      Spin to win <br /> EYES, SOL <br />
                      and more!
                    </p>
                  </div>
                  <img src={eyeroll} alt="Eye roll" className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/4 -top-4 w-20 h-20" />

                  {dailySpin ? (
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 2L7 13L2 8" stroke="#22C31F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <button onClick={() => setIsEyeRollOpen(true)} className="bg-[#22C31F] text-black text-sm w-[70px] rounded-full">
                      SPIN
                    </button>
                  )}
                </div>

                {/* Garis pembatas */}
                <div className="border-t-2 border-[#392F24] mt-2 mb-2"></div>

                {/* Play in FREE MODE task */}

                <div className="flex justify-between items-center px-6">
                  <div>
                    <p className={`${freeMode ? "text-[#727272]" : "text-white"} text-[11px]`}>Play 10x in FREE MODE</p>
                    <p className={`${freeMode ? "text-[#727272]" : "text-[#22C31F]"} text-[11px]`}>+100 EYES</p>
                  </div>

                  {freeMode ? (
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 2L7 13L2 8" stroke="#22C31F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <button className={`bg-[#22C31F] text-black text-sm w-[70px] rounded-full ${disabledButtons.freeMode ? "opacity-50 cursor-not-allowed" : ""}`} onClick={() => handleFreeModeClaim(100)} disabled={disabledButtons.freeMode}>
                      {freemodeButton}
                    </button>
                  )}
                </div>
              </div>
            </motion.div>

            {/* Socials Section */}
            <motion.div
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{
                duration: 0.5,
                type: "spring",
                stiffness: 100,
                damping: 15,
              }}
            >
              <p className="font-passion text-white text-[20px]">Socials</p>
              <div className="bg-[#221C15] rounded-3xl mb-6 py-5">
                {/* join telegram group */}
                <div className="flex justify-between items-center px-6">
                  <div>
                    <p className={`${joinedTelegramGrup ? "text-[#727272]" : "text-white"} text-[11px]`}>Join Telegram Group</p>
                    <p className={`${joinedTelegramGrup ? "text-[#727272]" : "text-[#22C31F]"} text-[11px]`}>+2000 EYES</p>
                  </div>

                  {joinedTelegramGrup ? (
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 2L7 13L2 8" stroke="#22C31F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : checkGroupButton == "Join" ? (
                    <a href="https://t.me/HouseOfXDragon" target="_blank" rel="noopener noreferrer" onClick={handleGroupLinkClick}>
                      <button className="bg-[#22C31F] text-black text-sm w-[70px] rounded-full">{checkGroupButton}</button>
                    </a>
                  ) : (
                    <button className="bg-[#22C31F] text-black text-sm w-[70px] rounded-full" onClick={() => handleCheckUsernameAndGroup(2000)}>
                      {checkGroupButton}
                    </button>
                  )}
                </div>

                <div className="border-t-2 border-[#392F24] mt-2 mb-2"></div>

                {/* subscribe telegram announcement */}
                {/*
              <div className="flex justify-between items-center px-6 ">
                <div>
                  <p
                    className={`${
                      subscribeTelegramAnnouncement
                        ? "text-[#727272]"
                        : "text-white"
                    } text-[11px]`}
                  >
                    Subscribe  Telegram Announcement
                  </p>
                  <p
                    className={`${
                      subscribeTelegramAnnouncement
                        ? "text-[#727272]"
                        : "text-[#22C31F]"
                    } text-[11px]`}
                  >
                    +2000 EYES
                   </p>
                </div>

                {subscribeTelegramAnnouncement ? (
                  <svg
                    width="20"
                    height="15"
                    viewBox="0 0 20 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 2L7 13L2 8"
                      stroke="#22C31F"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                ) : (
                  <button
                    className="bg-[#22C31F] text-black w-[70px] rounded-full"
                    onClick={() =>
                      handleAction("subscribeTelegramAnnouncement")
                    }
                  >
                    Go
                  </button>
                )}
              </div>

              <div className="border-t-2 border-[#392F24] mt-2 mb-2"></div>
*/}
                {/* follow x*/}

                <div className="flex justify-between items-center px-6 ">
                  <div>
                    <p className={`${followX ? "text-[#727272]" : "text-white"} text-[11px]`}>Follow us on X </p>
                    <p className={`${followX ? "text-[#727272]" : "text-[#22C31F]"} text-[11px]`}>+3000 EYES</p>
                  </div>

                  {followX ? (
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 2L7 13L2 8" stroke="#22C31F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : followButton == "Follow" ? (
                    <a href="https://x.com/intent/follow?screen_name=dragoneyesxyz" target="_blank" rel="noopener noreferrer" onClick={handleLinkClick}>
                      <button className="bg-[#22C31F] text-black text-sm w-[70px] rounded-full">{followButton}</button>
                    </a>
                  ) : (
                    <button className={`bg-[#22C31F] text-black text-sm w-[70px] rounded-full ${disabledButtons.followX ? "opacity-50 cursor-not-allowed" : ""}`} onClick={() => handleFollowX(3000)} disabled={disabledButtons.followX}>
                      {followButton}
                    </button>
                  )}
                </div>
                <div className="border-t-2 border-[#392F24] mt-2 mb-2"></div>
                {/*  */}

                {/* add icon to username x*/}
                <div className="flex justify-between items-center px-6 ">
                  <div>
                    <p className={`${addIconToUsername ? "text-[#727272]" : "text-white"} text-[11px]`}>Add all three rock-paper-scissors emoji ✊🖐️✌️ to your username</p>
                    <p className={`${addIconToUsername ? "text-[#727272]" : "text-[#22C31F]"} text-[11px]`}>+5000 EYES</p>
                  </div>

                  {addIconToUsername ? (
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 2L7 13L2 8" stroke="#22C31F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <button className="bg-[#22C31F] text-black text-sm w-[70px] rounded-full" onClick={() => handleUname(5000)}>
                      {unameButton}
                    </button>
                  )}
                </div>
              </div>
            </motion.div>

            {/* weekly Section */}
            <motion.div
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{
                duration: 0.5,
                type: "spring",
                stiffness: 100,
                damping: 15,
              }}
            >
              <p className="font-passion text-white text-[20px]">Weekly</p>
              <div className="bg-[#221C15] rounded-3xl mb-6 py-5">
                {/* play 25x */}
                <div className="flex justify-between items-center px-6">
                  <div>
                    <p className={`${play25xWeekly ? "text-[#727272]" : "text-white"} text-[11px]`}>Play 25x in BET MODE</p>
                    <p className={`${play25xWeekly ? "text-[#727272]" : "text-[#22C31F]"} text-[11px]`}>+5,000 EYES + 3 airdrop invites</p>
                  </div>

                  {play25xWeekly ? (
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 2L7 13L2 8" stroke="#22C31F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <button
                      className={`bg-[#22C31F] text-black text-sm w-[70px] rounded-full ${disabledButtons.weeklyPlay ? "opacity-50 cursor-not-allowed" : ""}`}
                      onClick={() => handleWeeklyPlay(5000)}
                      disabled={disabledButtons.weeklyPlay}
                    >
                      {weeklyPlayButton}
                    </button>
                  )}
                </div>

                <div className="border-t-2 border-[#392F24] mt-2 mb-2"></div>

                {/* play 5x streak mode */}
                <div className="flex justify-between items-center px-6 ">
                  <div>
                    <p className={`${play5xstreakMode ? "text-[#727272]" : "text-white"} text-[11px]`}>Play 5x in STREAK MODE</p>
                    <p className={`${play5xstreakMode ? "text-[#727272]" : "text-[#22C31F]"} text-[11px]`}>+5000 EYES</p>
                  </div>

                  {play5xstreakMode ? (
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 2L7 13L2 8" stroke="#22C31F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <button
                      className={`bg-[#22C31F] text-sm text-black w-[70px] rounded-full ${disabledButtons.weeklyStreak ? "opacity-50 cursor-not-allowed" : ""}`}
                      onClick={() => handleWeeklyStreakPlay(5000)}
                      disabled={disabledButtons.weeklyStreak}
                    >
                      {weeklyStreakButton}
                    </button>
                  )}
                </div>

                <div className="border-t-2 border-[#392F24] mt-2 mb-2"></div>

                {/* topup minimum 1 sol x*/}
                <div className="flex justify-between items-center px-6 ">
                  <div>
                    <p className={`${topUpMin1Sol ? "text-[#727272]" : "text-white"} text-[11px]`}>Top up wallet min 1 SOL</p>
                    <p className={`${topUpMin1Sol ? "text-[#727272]" : "text-[#22C31F]"} text-[11px]`}>+5000 EYES</p>
                  </div>

                  {topUpMin1Sol ? (
                    <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 2L7 13L2 8" stroke="#22C31F" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  ) : (
                    <button className={`bg-[#22C31F] text-sm text-black w-[70px] rounded-full ${disabledButtons.topup ? "opacity-50 cursor-not-allowed" : ""}`} onClick={() => handleTopup(5000)} disabled={disabledButtons.topup}>
                      {topupButtom}
                    </button>
                  )}
                </div>
              </div>
              <div className="h-32"></div>
            </motion.div>
          </div>
        ) : (
          <div className="w-full text-center flex justify-center items-center h-screen">
            <p className="font-passion text-white text-[20px]">Loading Quest Data...</p>
          </div>
        )
      ) : (
        <div className="overflow-y-scroll max-h-full text-white p-6 no-scrollbar ">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{
              duration: 0.5,
              type: "spring",
              stiffness: 100,
              damping: 15,
            }}
          >
            <p className="font-passion text-white text-[20px] w-full text-center">Play Roshambo Telegram Version to Access Quest</p>
            <div className="bg-[#221C15] px-6 py-5 rounded-3xl mb-6 w-full text-center">
              <div className="grid  items-center w-full text-center">
                <p className="text-white text-[11px] w-full text-center">Invite Friends to get 20% referral commission and EYES</p>
                <p className="text-white text-[11px] w-full text-center">Access quests to get weekly and daily rewards!</p>
              </div>
            </div>
          </motion.div>
        </div>
      )}
      <Wallet3 />
      <EyeRoll isOpen={isEyeRollOpen} onClose={() => setIsEyeRollOpen(false)} disableEyeroll={() => setDailySping(true)} />
      <ShareReferralModal isOpen={isShareModalOpen} onShare={shareReferralCode} invitesLeft={invitesLeft} onClose={() => setIsShareModalOpen(false)} />
      <AnimatePresence>{showEyesTokenModal && <SpinRewardModal isOpen={showEyesTokenModal} onClose={() => setShowEyesTokenModal(false)} message={questRewardMessage} />}</AnimatePresence>
    </div>
  );
};

export default QuestV2;
