import PropTypes from "prop-types";
import solAirDropImg from "../assets/img/airDrop.png";
import { PiCopySimpleBold } from "react-icons/pi";
import { RiShareBoxLine } from "react-icons/ri";
import { useAtom } from "jotai";
import { userAtom, telegramWebAppAtom, isAuthenticatedAtom } from "../store/Atoms";
import { toast } from "react-toastify";

const ShareReferralModal = ({ isOpen, invitesLeft, onClose, onShare }) => {
  const [user] = useAtom(userAtom);
  const [telegram] = useAtom(telegramWebAppAtom);
  const [isAuthenticated] = useAtom(isAuthenticatedAtom);

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text);
    toast.success(`${type} copied to clipboard`);
  };

  const handleShareToStory = async () => {
    try {
      console.log("Using Telegram Story sharing");

      const imageToSend = "https://i.ibb.co.com/n1rspJf/story-Image.png";

      if (isAuthenticated) {
        await telegram?.shareToStory(imageToSend, {
          text: invitesLeft > 0 ? "Claim your 0.03 SOL airdrop NOW!" : "Check out this trending Rock Paper Scissors game!",
          widget_link: {
            url: `https://t.me/dragoneyesxyz_bot/roshambo?startapp=${user.referralCode}`,
            name: "Play Now!",
          },
        });
        console.log("Story shared successfully");
      } else {
        toast.error("Please connect to Telegram first");
      }
    } catch (error) {
      console.error("Error sharing to story:", error);
      toast.error("Failed to share story. Please try again.");
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-[#343433FA] bg-opacity-98 p-6 rounded-lg shadow-xl font-passion max-w-md w-4/5 relative">
        <button onClick={onClose} className="absolute top-3 text-xs font-bold right-3 w-6 h-6 text-[#EC4141] rounded-full border-[3px] border-[#EC4141] flex items-center justify-center hover:bg-[#D63737] transition-colors">
          ✕
        </button>
        <div className="flex flex-col justify-center items-center text-center">
          <img src={solAirDropImg} alt="SOL Airdrop" className="w-56" />
          <p className="text-white text-sm font-bold mb-1">My referral code</p>
          <div className="flex items-center bg-[#4B4B4B] rounded-full px-4 py-2 mb-4">
            <span className="text-white">{user.referralCode}</span>
            <button onClick={() => copyToClipboard(user.referralCode, "referral")} className="ml-2">
              <PiCopySimpleBold className="text-white" />
            </button>
          </div>

          <p className="text-white text-xs mb-4">
            {invitesLeft > 0 ? (
              <>
                <span className="text-white">Share the link directly to your friend or in a group.</span> <br /> <br />
                <span className="text-[#FFF4BC]">
                  Your friend will get 0.03 SOL airdrop, and you&apos;ll earn 10K $EYES + 20% fee commission <br />
                  from the games your friends playing.
                </span>
              </>
            ) : (
              <>
                <span className="text-white">Share the link directly to your friend or in a group.</span> <br /> <br />
                <span className="text-[#FFF4BC]">You will earn 10K $EYES + 20% fee commission from the games your friends playing.</span>
              </>
            )}
          </p>
          {invitesLeft > 0 && <p className="text-[#22C31F] text-[16px] mb-6">You have {invitesLeft} airdrop invites left this week.</p>}
        </div>
        <div className="flex flex-col justify-center items-center gap-4">
          <button onClick={onShare} className="px-6 py-3 bg-[#5E17EB] text-white rounded-full transition-colors w-full">
            <span className="flex items-center justify-center">
              Share to Chat
              <RiShareBoxLine className="ml-2 text-white" />
            </span>
          </button>
          <button onClick={handleShareToStory} className="px-6 py-3 bg-[#22C31F] text-white rounded-full transition-colors w-full">
            <span className="flex items-center justify-center">
              Share Story
              <RiShareBoxLine className="ml-2 text-white" />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
};

ShareReferralModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  invitesLeft: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  onShare: PropTypes.func.isRequired,
};

export default ShareReferralModal;
