import { useAtom } from "jotai";
import BottomNavbar from "../components/BottomNavbar";
import useTelegramWebApp from "../hooks/useTelegramWebApp";
import { isAuthenticatedAtom, telegramUserDataAtom, hasSeenSplashScreenAtom, progressAtom, telegramWebAppAtom, userAtom } from "../store/Atoms";
import useInitializeOpenlogin from "../hooks/useInitializeOpenLogin";
import { useCallback, useEffect, useState, useMemo } from "react";
import EyeRollConnectModal from "../components/eyeroll/EyeRollConnectModal";
import { AnimatePresence, motion } from "framer-motion";
import analytics from "../utils/segment";
import RoshamboHeader from "../components/RoshamboHeader";
import ArenaMobile from "../components/Roshambo/ArenaMobile";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";

const Telegram = () => {
  const { authenticateUser } = useTelegramWebApp();
  const [isAuthenticated] = useAtom(isAuthenticatedAtom);
  const [telegramUserData] = useAtom(telegramUserDataAtom);
  const [user] = useAtom(userAtom);
  const [hasSeenSplashScreen, setHasSeenSplashScreen] = useAtom(hasSeenSplashScreenAtom);
  const [progress, setProgress] = useAtom(progressAtom);
  const [telegram] = useAtom(telegramWebAppAtom);
  const [hasTrackedPlatform, setHasTrackedPlatform] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isContentReady, setIsContentReady] = useState(false);

  useInitializeOpenlogin();

  const isValidPlatform = useMemo(() => {
    if (typeof window !== "undefined") {
      const storedPlatformValid = sessionStorage.getItem("isValidPlatform");
      if (storedPlatformValid !== null) {
        return JSON.parse(storedPlatformValid);
      }
    }

    if (telegram && telegram.platform) {
      const isValid = telegram.platform === "android" || telegram.platform === "ios";
      sessionStorage.setItem("isValidPlatform", JSON.stringify(isValid));
      return isValid;
    }

    return false;
  }, [telegram]);

  useEffect(() => {
    if (telegram && telegram.platform) {
      const hasTrackedPlatformThisSession = sessionStorage.getItem("hasTrackedPlatformDetected");
      if (!hasTrackedPlatformThisSession && !hasTrackedPlatform) {
        analytics.track("Platform Detected", {
          platform: telegram.platform,
          user_id: telegramUserData?.id,
        });
        sessionStorage.setItem("hasTrackedPlatformDetected", "true");
        setHasTrackedPlatform(true);
      }
    }
  }, [telegram, telegramUserData, hasTrackedPlatform]);

  useEffect(() => {
    const handleError = (error) => {
      console.error("Unhandled error in Landing Page Telegram:", error);
      setError(error);

      let errorMessage = "An unknown error occurred. Please try again.";

      if (error.message?.includes("React") || error.message?.includes("render") || error.message?.includes("component")) {
        errorMessage = "There was an error with the application interface. Please reload the page.";
        analytics.track("Frontend Error", {
          error: error.message,
          stack: error.stack,
          user_id: telegramUserData?.id,
        });
      } else if (error.message?.includes("SIWT") || error.message?.includes("telegram auth")) {
        errorMessage = "Failed to authenticate with Telegram. Please try again or contact support.";
        analytics.track("SIWT Authentication Error", {
          error: error.message,
          stack: error.stack,
          user_id: telegramUserData?.id,
        });
      } else if (error.message?.includes("OpenLogin") || error.message?.includes("wallet")) {
        errorMessage = "Failed to initialize wallet. Please try again or contact support.";
        analytics.track("OpenLogin Error", {
          error: error.message,
          stack: error.stack,
          user_id: telegramUserData?.id,
        });
      } else if (error.message?.includes("canister") || error.message?.includes("IC") || error.message?.includes("replica")) {
        errorMessage = "Failed to connect to ICP blockchain. Please try again in a moment.";
        analytics.track("Canister Connection Error", {
          error: error.message,
          stack: error.stack,
          user_id: telegramUserData?.id,
        });
      }

      toast.error(errorMessage);
    };

    window.addEventListener("error", handleError);
    window.addEventListener("unhandledrejection", (event) => handleError(event.reason));

    return () => {
      window.removeEventListener("error", handleError);
      window.removeEventListener("unhandledrejection", (event) => handleError(event.reason));
    };
  }, [telegramUserData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoading) {
        setError(new Error("Application can't be loaded. Please check your connection and try again."));
        analytics.track("Application Load Timeout in Landing Page Telegram", {
          user_id: telegramUserData?.id,
        });
      }
    }, 30000);

    return () => clearTimeout(timer);
  }, [isLoading, telegramUserData]);

  useEffect(() => {
    if (hasSeenSplashScreen) {
      setIsLoading(false);
    }
  }, [hasSeenSplashScreen]);

  useEffect(() => {
    const handleAuthenticate = async () => {
      if (!isAuthenticated && isValidPlatform) {
        try {
          setProgress(0);
          await authenticateUser();
          await new Promise((resolve) => setTimeout(resolve, 2000));
          setProgress(30);
          await new Promise((resolve) => setTimeout(resolve, 2000));
          setProgress(60);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          setProgress(80);
          await new Promise((resolve) => setTimeout(resolve, 800));
          setProgress(100);
        } catch (error) {
          console.error("Authentication failed:", error);
          analytics.track("In Landing Page Authentication Error", {
            error: error.message,
            user_id: telegramUserData?.id,
          });
        }
      }
    };

    if (telegramUserData && !isAuthenticated && isValidPlatform) {
      handleAuthenticate();
    }
  }, [telegramUserData, authenticateUser, isAuthenticated, setProgress, isValidPlatform]);

  const trackSession = useCallback(() => {
    if (isAuthenticated && telegramUserData && user && user.principal) {
      const sessionKey = `hasTrackedDragonEyesSession_${telegramUserData.id}`;
      const hasTrackedThisSession = sessionStorage.getItem(sessionKey);

      if (!hasTrackedThisSession) {
        analytics.identify(`T_${telegramUserData.id}`, {
          user_id: telegramUserData.id,
          name: telegramUserData.first_name,
          game_name: user.userName,
          SOL_Balance: user.userBalance,
          principal_id: user.principal,
        });
        analytics.track("Dragon Eyes Session Start", {
          user_id: telegramUserData.id,
          name: telegramUserData.first_name,
          game_name: user.userName,
        });
        sessionStorage.setItem(sessionKey, "true");
        console.log("Session tracked successfully");
      }
    } else if (telegramUserData) {
      analytics.track("Incomplete Session Data", {
        isAuthenticated,
        hasTelegramUserData: !!telegramUserData,
        hasUser: !!user,
        hasPrincipal: !!user?.principal,
      });
      console.log("Incomplete session data tracked");
    }
  }, [isAuthenticated, telegramUserData, user]);

  useEffect(() => {
    if (isAuthenticated && telegramUserData && user) {
      trackSession();
    }
  }, [isAuthenticated, telegramUserData, user, trackSession]);

  useEffect(() => {
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
    document.getElementsByTagName("head")[0].appendChild(meta);

    return () => {
      document.getElementsByTagName("head")[0].removeChild(meta);
    };
  }, []);

  useEffect(() => {
    if (isValidPlatform) {
      const timer = setTimeout(() => {
        setIsContentReady(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isValidPlatform]);

  const handleEyeRollComplete = () => {
    setHasSeenSplashScreen(true);
  };

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
        <h1 className="text-2xl font-bold text-red-600 mb-4">An Error Occurred</h1>
        <p className="text-lg text-center mb-4">{error.message}</p>
        <div className="flex flex-col gap-4">
          <button onClick={() => window.location.reload()} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            Reload Application
          </button>
          <a href="https://t.me/HouseOfXDragon/1" target="_blank" rel="noopener noreferrer" className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded text-center">
            Contact Support
          </a>
        </div>
      </div>
    );
  }

  if (!isValidPlatform) {
    const searchParams = new URLSearchParams(window.location.search);
    const startappParam = searchParams.get("tgWebAppStartParam");
    // toast.info(window.location.search + " url");

    let qrCodeUrl;
    if (startappParam) {
      qrCodeUrl = `https://t.me/dragoneyesxyz_bot/roshambo?startapp=${startappParam}`;
      // toast.info(startappParam + " startappParam");
    } else {
      qrCodeUrl = "https://t.me/dragoneyesxyz_bot/roshambo";
      // toast.error("no referral code.");
    }

    return (
      <div className="flex flex-col items-center justify-center h-screen bg-gray-100 p-4">
        <p className="text-xl font-bold text-center mb-8 text-gray-800">Scan this QR code with the Telegram app on your phone to access Dragon Eyes.</p>
        <div className="bg-white p-6 rounded-xl shadow-2xl">
          <QRCode value={qrCodeUrl} size={300} level="H" renderAs="svg" includeMargin={true} />
        </div>
        <p className="text-base text-center mt-6 text-gray-600">This application can only be accessed via the Telegram application on mobile phones.</p>
      </div>
    );
  }

  if (!isContentReady) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <FaSpinner className="animate-spin text-4xl text-blue-500" />
        <span className="ml-2 text-lg text-gray-700">Loading...</span>
      </div>
    );
  }

  return (
    <main className="overflow-hidden h-screen">
      <AnimatePresence>
        {!hasSeenSplashScreen && (
          <motion.div initial={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.5 }}>
            <EyeRollConnectModal onComplete={handleEyeRollComplete} progress={progress} />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {hasSeenSplashScreen && (
          <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
            <RoshamboHeader />
            <ArenaMobile />
            <BottomNavbar />
          </motion.div>
        )}
      </AnimatePresence>
    </main>
  );
};

export default Telegram;
