import EyeRoll from "../components/eyeroll/EyeRoll";
import BottomNavbar from "../components/BottomNavbar";
import { useEffect } from "react";

const EyeeRollLanding = () => {
  useEffect(() => {
    const meta = document.createElement("meta");
    meta.name = "viewport";
    meta.content = "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
    document.getElementsByTagName("head")[0].appendChild(meta);

    return () => {
      document.getElementsByTagName("head")[0].removeChild(meta);
    };
  }, []);

  return (
    <div>
      <EyeRoll />
      <BottomNavbar />
    </div>
  );
};

export default EyeeRollLanding;
