import LastHouseShot from "../components/LastHouseShot";
import { useAtom } from "jotai";
import { roshamboLastBetAtom, roshamboNewBetAtom } from "../store/Atoms";
import { FiInfo } from "react-icons/fi";
import { useState } from "react";
import mainCharacter from "../assets/img/maincar.png";
import telegramIcon from "../assets/landing/tele-icn.svg";
import xIcon from "../assets/landing/x-icn.svg";
import analytics from "../utils/segment";

const Home2 = () => {
  const [lastBets] = useAtom(roshamboLastBetAtom);
  const [newbet] = useAtom(roshamboNewBetAtom);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);

  const handlePlayNow = () => {
    window.open("https://t.me/dragoneyesxyz_bot/roshambo?startapp=DXE15118R7E2S5T", "_blank");
    analytics.track("User Clicked Play Now on Homepage", {
      game_name: "Roshambo",
      category: "User Engagement",
      label: "User Clicked Play Now",
    });
  };

  const handleTelegramClick = () => {
    window.open("https://t.me/HouseOfXDragon/", "_blank");
  };

  const handleXClick = () => {
    window.open("https://x.com/intent/follow?screen_name=dragoneyesxyz", "_blank");
  };

  const currentYear = new Date().getFullYear();

  function formatTimeAgo(timestamp) {
    const now = Date.now();
    const differenceInMillis = now - timestamp;
    const differenceInMinutes = Math.floor(differenceInMillis / 60000);

    if (differenceInMinutes < 1) {
      return "just now";
    } else if (differenceInMinutes < 60) {
      return `${differenceInMinutes}m ago`;
    } else if (differenceInMinutes < 1440) {
      // kurang dari 24 jam
      const hours = Math.floor(differenceInMinutes / 60);
      return `${hours}h ago`;
    } else {
      const days = Math.floor(differenceInMinutes / 1440);
      return `${days}d ago`;
    }
  }

  // Tambahkan fungsi helper untuk memformat bet amount
  function formatBetAmount(amount) {
    const betInSol = amount / 1e9;
    // Menggunakan parseFloat untuk menghilangkan trailing zeros
    return parseFloat(betInSol.toFixed(3)).toString();
  }

  // Skeleton untuk Recent Plays
  const RecentPlaysSkeleton = () => (
    <div className="animate-pulse">
      <div className="h-6 w-48 bg-gray-600 rounded mb-4 mx-auto" />
      <div className="space-y-3">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="flex justify-between p-3 bg-gray-800 rounded">
            <div className="flex gap-2">
              <div className="h-4 w-24 bg-gray-600 rounded" />
              <div className="h-4 w-16 bg-gray-600 rounded" />
              <div className="h-4 w-20 bg-gray-600 rounded" />
              <div className="h-4 w-16 bg-gray-600 rounded" />
            </div>
            <div className="h-4 w-16 bg-gray-600 rounded" />
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <main className="min-h-screen flex flex-col relative">
      <LastHouseShot />
      {/* Background Image */}
      <div className="fixed inset-0 bg-[url('/src/assets/img/bg-desktop.png')] bg-cover bg-center -z-10"></div>
      {/* Dark Overlay */}
      <div className="fixed inset-0 bg-black opacity-50 -z-10"></div>

      <section className="flex-grow flex justify-center">
        <div className="container mx-auto px-4 flex flex-col">
          {/* Desktop Layout */}
          <div className="hidden lg:flex flex-row justify-between items-center py-12 lg:py-20">
            {/* Left Section - Desktop */}
            <div className="flex flex-col z-10 w-full lg:w-2/3 pl-12 text-left">
              <p className="text-white font-passion text-[26px]">#1 Rock Paper Scissors game on Solana</p>
              <div className="mb-9">
                <h1 className="text-[#FAAC52] font-normal font-passero text-[96px] leading-none">ROSHAMBO</h1>
                <p className="text-[#FAAC52] font-normal font-passero text-[36px] leading-none">by Dragon Eyes</p>
              </div>
              <p className="text-[#F3E6D3] font-passion text-2xl mb-9">
                Win to 2x your SOL. <br />
                20x in Streak mode.
              </p>
              <div className="flex flex-col items-start">
                <button onClick={handlePlayNow} className="bg-[#E35721] px-8 py-3 rounded-full w-80 font-passion text-2xl text-white hover:opacity-90 transition-opacity duration-300 leading-none mb-2">
                  Play Now <br />
                  <span className="text-white/60 text-lg font-passion">get +0.005 SOL airdrop!</span>
                </button>
              </div>
              <p className="text-white/60 text-xl font-passion text-left ml-20 mb-4">(daily limited quota)</p>

              {/* Recent Plays Section */}
              <div className="w-full">
                {lastBets ? (
                  <>
                    <div className="flex items-center gap-2 mb-4">
                      <h2 className="text-white font-passion text-2xl">RECENT PLAYS</h2>
                      <div className="relative">
                        <FiInfo className="text-white w-4 h-4 cursor-help" onMouseEnter={() => setShowInfoTooltip(true)} onMouseLeave={() => setShowInfoTooltip(false)} />
                        {showInfoTooltip && <div className="absolute w-64 bg-white font-passion text-black text-xs rounded-md py-2 px-3 -right-32 top-6 z-50">Live feed of all player bets, results, and rewards</div>}
                      </div>
                    </div>
                    <div className="bg-[#282828] bg-opacity-90 rounded-lg overflow-hidden border-[1px] border-[#B4B4B480]">
                      <div className="overflow-y-auto no-scrollbar max-h-[250px]">
                        <div className="grid gap-2 divide-y divide-[#B4B4B480]">
                          {lastBets.slice(0, 50).map((bet, id) => (
                            <div key={bet[0]} className={`flex items-center justify-between bg-opacity-80 px-3 pt-2 pb-1 text-sm lg:text-sm text-white font-passion ${id === newbet ? "animate-dim" : ""}`}>
                              <div className="flex gap-1 lg:gap-2 items-center overflow-x-auto whitespace-nowrap">
                                <span className="text-gray-400">
                                  {bet[1].username && bet[1].username !== bet[1].caller["__principal__"] ? bet[1].username : `${bet[1].caller["__principal__"].slice(0, 5)}...${bet[1].caller["__principal__"].slice(-5)}`}
                                </span>
                                <span>bet {formatBetAmount(bet[1].betAmount)} SOL,</span>
                                <span>threw {bet[1].guess == 1 ? "Rock" : bet[1].guess == 2 ? "Paper" : "Scissors"}</span>
                                <span>and</span>
                                <span className={bet[1].result === "draw" ? "text-yellow-300" : bet[1].result === "win" ? "text-green-500" : "text-red-500"}>
                                  {bet[1].result === "draw" ? "draw" : bet[1].result === "win" ? "doubled" : "rekt"}
                                </span>
                              </div>
                              <div className="text-gray-400 text-[10px] lg:text-sm ml-2">{formatTimeAgo(Number(bet[1].time_created))}</div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <RecentPlaysSkeleton />
                )}
              </div>
            </div>

            {/* Right Section - Desktop */}
            <div className="flex flex-col items-center z-10 lg:w-1/2">
              <img src={mainCharacter} alt="Main Character" className="w-80 h-auto mb-8" />
              <div className="flex gap-4">
                <img src={telegramIcon} alt="Telegram" className="w-10 h-10 cursor-pointer hover:opacity-80" onClick={handleTelegramClick} />
                <img src={xIcon} alt="X" className="w-10 h-10 cursor-pointer hover:opacity-80" onClick={handleXClick} />
              </div>
            </div>
          </div>

          {/* Mobile Layout */}
          <div className="flex lg:hidden flex-col items-center py-8">
            <p className="text-white font-passion text-base text-center mb-2">#1 Rock Paper Scissors game on Solana</p>
            <h1 className="text-[#FAAC52] font-normal font-passero text-6xl leading-none text-center">ROSHAMBO</h1>
            <p className="text-[#FAAC52] font-normal font-passero text-2xl mb-4 text-center leading-tight">by Dragon Eyes</p>
            <p className="text-[#F3E6D3] font-passion text-xl mb-4 text-center leading-tight">
              Win to 2x your SOL. <br />
              20x in Streak mode.
            </p>

            <div className="relative w-full flex flex-col items-center mb-16">
              <img src={mainCharacter} alt="Main Character" className="w-64 h-auto" />
              <div className="absolute -bottom-12 flex flex-col items-center">
                <button onClick={handlePlayNow} className="bg-[#E35721] px-8 py-3 rounded-full w-64 font-passion text-xl text-white hover:opacity-90 transition-opacity duration-300">
                  Play Now <br />
                  <span className="text-white/60 text-lg font-passion">get +0.005 SOL airdrop!</span>
                </button>
                <span className="text-white/60 text-lg font-passion mt-1">(daily limited quota)</span>
              </div>
            </div>

            {/* Recent Plays Section - Mobile */}
            <div className="w-full mb-8">
              {lastBets ? (
                <>
                  <div className="flex justify-center items-center gap-2 mb-4">
                    <h2 className="text-white font-passion text-xl">RECENT PLAYS</h2>
                    <div className="relative">
                      <FiInfo className="text-white w-4 h-4 cursor-pointer" onClick={() => setShowInfoTooltip(!showInfoTooltip)} />
                      {showInfoTooltip && (
                        <div className="absolute w-64 font-passion bg-white text-black text-xs rounded-md py-2 px-3 left-[10%] top-6 transform -translate-x-1/2 shadow-lg z-50">
                          Live feed of all player bets, results, and rewards
                          <div className="absolute -top-1 right-[46%] transform w-2 h-2 bg-white rotate-45"></div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="bg-[#282828] bg-opacity-90 rounded-lg overflow-hidden border-[1px] border-[#B4B4B480]">
                    <div className="overflow-y-auto no-scrollbar max-h-[250px]">
                      <div className="grid gap-2 divide-y divide-[#B4B4B480]">
                        {lastBets.slice(0, 50).map((bet, id) => (
                          <div key={bet[0]} className={`flex items-center justify-between bg-opacity-80 px-3 pt-2 pb-1 text-xs lg:text-sm text-white font-passion ${id === newbet ? "animate-dim" : ""}`}>
                            <div className="flex gap-1 lg:gap-2 items-center overflow-x-auto whitespace-nowrap">
                              <span className="text-gray-400">
                                {bet[1].username && bet[1].username !== bet[1].caller["__principal__"] ? bet[1].username : `${bet[1].caller["__principal__"].slice(0, 5)}...${bet[1].caller["__principal__"].slice(-5)}`}
                              </span>
                              <span>bet {formatBetAmount(bet[1].betAmount)} SOL,</span>
                              <span>threw {bet[1].guess == 1 ? "Rock" : bet[1].guess == 2 ? "Paper" : "Scissors"}</span>
                              <span>and</span>
                              <span className={bet[1].result === "draw" ? "text-yellow-300" : bet[1].result === "win" ? "text-green-500" : "text-red-500"}>
                                {bet[1].result === "draw" ? "draw" : bet[1].result === "win" ? "doubled" : "rekt"}
                              </span>
                            </div>
                            <div className="text-gray-400 text-[10px] lg:text-sm ml-2">{formatTimeAgo(Number(bet[1].time_created))}</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <RecentPlaysSkeleton />
              )}
            </div>

            {/* Social Icons - Mobile */}
            <div className="flex gap-4">
              <img src={telegramIcon} alt="Telegram" className="w-8 h-8 cursor-pointer hover:opacity-80" onClick={handleTelegramClick} />
              <img src={xIcon} alt="X" className="w-8 h-8 cursor-pointer hover:opacity-80" onClick={handleXClick} />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-4 text-center text-white font-passion z-10">
        <a href="https://www.dragoneyes.xyz/" className="hover:underline">
          © Dragon Eyes {currentYear}
        </a>
      </footer>
    </main>
  );
};

export default Home2;
