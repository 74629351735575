import { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useAtomValue } from "jotai";
import { coreAtom } from "../../store/Atoms";
import EyesTokenModal from "./SpinRewardModal";
import { AnimatePresence } from "framer-motion";
import analytics from "../../utils/segment";
import { useAtom } from "jotai";
import { telegramUserDataAtom, userAtom } from "../../store/Atoms";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const EyeRoll = ({ isOpen, onClose, disableEyeroll }) => {
  const [telegramUserData] = useAtom(telegramUserDataAtom);
  const [user] = useAtom(userAtom);
  const [showEyesTokenModal, setShowEyesTokenModal] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [result, setResult] = useState(null);
  const [rotation, setRotation] = useState(0);
  const chartRef = useRef(null);
  const wheelContainerRef = useRef(null);
  const [wheelSize, setWheelSize] = useState(0);
  const [key, setKey] = useState(0);
  const dragonCore = useAtomValue(coreAtom);
  const [message, setMessage] = useState(false);
  const [spin, setSpin] = useState(true);

  const segments = [
    { value: "150 EYES", chance: 10, color: "#D0A00F" },
    { value: "50 EYES", chance: 10, color: "#4E5EB8" },
    { value: "250 EYES", chance: 5, color: "#436C39", special: true },
    { value: "ROLL AGAIN", chance: 10, color: "#FA6334" },
    { value: "75 EYES", chance: 10, color: "#339CF0" },
    { value: "500 EYES", chance: 5, color: "#A86025", special: true },
    { value: "25 EYES", chance: 10, color: "#353535" },
    { value: "U GOT NOTHING", chance: 10, color: "#8E4E4A" },
    { value: "250 EYES", chance: 5, color: "#2B3878", special: true },
    { value: "75 EYES", chance: 10, color: "#E6306E" },
    { value: "100 EYES", chance: 10, color: "#A138B3" },
    { value: "0.01 SOL", chance: 5, color: "#4CA14F", special: true },
  ];

  const chartData = {
    labels: segments.map((segment) => segment.value),
    datasets: [
      {
        data: segments.map((segment) => segment.chance),
        backgroundColor: segments.map((segment) => segment.color),
      },
    ],
  };

  const chartOptions = {
    cutout: "20%",
    rotation: 0,
    circumference: 360,
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
    events: [],
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
      datalabels: {
        color: (context) => {
          const segment = segments[context.dataIndex];
          return segment.chance === 5 ? "#FFD700" : "#FFFFFF"; // Warna emas untuk segmen dengan chance 5, putih untuk yang lain
        },
        formatter: (_, context) => context.chart.data.labels[context.dataIndex],
        font: (context) => {
          const segment = segments[context.dataIndex];
          return {
            weight: "bold",
            size: segment.chance === 5 ? 20 : 13,
            family: "Passion One",
          };
        },
        offset: -3,
        rotation: (context) => {
          const angle = (context.dataIndex * (360 / segments.length) * Math.PI) / 182;
          return angle * (180 / Math.PI) - 69;
        },
      },
    },
    hover: { mode: null },
    onClick: null,
  };

  // brief backend yang akan diintegrasikan
  //fungsi : await dragonCore.spinEyeRoll() fungsi ini adalah fungsi yang akan dipanggil frontend untuk mengambil data dari backend/canister
  //kembalian data dari backend :
  //success --> roll sukses, ada isinya lagi success.value = angka random 0-100, tempat jarum berhenti (harus dihitung dulu, angka/100 kali 360). success.message = message yang muncul di modal akhir, isinya info hadiah yang didapatkan

  const handleSpin = useCallback(async () => {
    if (isSpinning) return;

    setIsSpinning(true);
    const initialSpinDuration = 2000; // Durasi putaran awal dipercepat
    const finalSpinDuration = 2000; // Durasi putaran akhir
    let startTime;
    let finalRotation;
    let backendRotation;
    let phase = "initial"; // 'initial' atau 'final'

    const animate = (currentTime) => {
      if (!startTime) startTime = currentTime;
      const elapsedTime = currentTime - startTime;

      if (phase === "initial") {
        // Putaran awal
        const progress = (elapsedTime % initialSpinDuration) / initialSpinDuration;
        const currentRotation = progress * 360 * 8; // 8 putaran penuh untuk rotasi lebih cepat
        setRotation(currentRotation);

        if (backendRotation !== undefined) {
          // Jika backend telah merespons, mulai fase akhir
          phase = "final";
          startTime = currentTime; // Reset waktu mulai untuk fase akhir
        }
        requestAnimationFrame(animate);
      } else if (phase === "final") {
        // Putaran akhir menuju hasil
        const progress = Math.min(elapsedTime / finalSpinDuration, 1);
        const easeOutCubic = 1 - Math.pow(1 - progress, 3);
        const currentRotation = easeOutCubic * finalRotation;

        setRotation(currentRotation);

        if (progress < 1) {
          requestAnimationFrame(animate);
        } else {
          // Animasi selesai
          setRotation(backendRotation);
          setIsSpinning(false);
          // Menggeser hasil sedikit ke kiri dengan menambahkan offset untuk fine tune pemberhentian terakir agar sesuai dengan apa yang ditunjukan jarum
          const offset = 13; // Anda bisa menyesuaikan nilai ini
          const adjustedRotation = (backendRotation - offset) % 360;
          const segmentIndex = Math.floor((360 - adjustedRotation) / (360 / segments.length)) % segments.length;
          setResult(segments[segmentIndex].value);
          console.log(segments[segmentIndex].value);
          setShowEyesTokenModal(true);
        }
      }
    };

    requestAnimationFrame(animate);
    var response = null;
    try {
      // Panggil fungsi backend
      // response = await dragonCore.spinEyeRoll()

      response = await dragonCore.spinEyeroll();

      // mock response mimicing backend
      /*const mockResponse = await new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            success: {
              value: Math.floor(Math.random() * 101),
              message: "Selamat! Anda mendapatkan hadiah.",
            },
          });
        }, 3000); // Waktu respons backend dipercepat
      }); */

      // ganti seluruh mockResponse menjadi response jika sudah ada backend
      if (response.success) {
        const { first_name, id } = telegramUserData;
        analytics.track("Quest - Spin Eyeroll", {
          user_id: id,
          name: first_name,
          game_name: user?.userName,
          label: "quest",
          result: response.success.message,
        });
        backendRotation = (Number(response.success.value) / 100) * 360;
        finalRotation = 360 * 2 + backendRotation; // 8 putaran penuh + rotasi dari backend
        console.log(response.success.message);
        setMessage(response.success.message);
        var message = response.success.message;
        if (message != "Roll Again!") {
          disableEyeroll();
          setSpin(false);
        }
      } else {
        setIsSpinning(false);
        console.error("Spin gagal:", response.error);
      }
    } catch (error) {
      setIsSpinning(false);
      console.error("Error saat melakukan spin:", error);
    }
  }, [isSpinning, segments]);

  useEffect(() => {
    const updateWheelSize = () => {
      if (wheelContainerRef.current) {
        const containerWidth = wheelContainerRef.current.offsetWidth;
        setWheelSize(containerWidth);
        setKey((prevKey) => prevKey + 1); // Force re-render of the chart
      }
    };

    const resizeObserver = new ResizeObserver(updateWheelSize);
    if (wheelContainerRef.current) {
      resizeObserver.observe(wheelContainerRef.current);
    }

    updateWheelSize();
    window.addEventListener("resize", updateWheelSize);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("resize", updateWheelSize);
    };
  }, [isOpen]); // Add isOpen to the dependency array

  useEffect(() => {
    if (isOpen) {
      // Add a small delay before rendering the chart
      const timer = setTimeout(() => {
        setKey((prevKey) => prevKey + 1);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-[#434343] rounded-lg p-8 relative max-w-md w-full">
        <button onClick={onClose} className="absolute top-2 right-2 border-[3px] border-[#EC4141] text-[#EC4141] text-center rounded-full w-8 h-8 flex items-center justify-center">
          &times;
        </button>
        <h2 className="text-white font-passion text-5xl mb-8 text-center">EYEROLL</h2>

        <div className="relative">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-0 h-0 border-l-[20px] border-l-transparent border-t-[40px] border-t-[#FFD105] border-r-[20px] border-r-transparent z-10"></div>
          <div ref={wheelContainerRef} className="relative w-full max-w-[300px] aspect-square mx-auto">
            <div
              className="absolute top-0 left-0 w-full h-full"
              style={{
                transform: `rotate(${rotation}deg)`,
                width: `${wheelSize}px`,
                height: `${wheelSize}px`,
              }}
            >
              <Doughnut key={key} ref={chartRef} data={chartData} options={chartOptions} />
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          {spin ? (
            <button onClick={handleSpin} className={`mt-8 text-white font-bold py-2 px-14 rounded-full ${isSpinning ? "bg-gray-500 cursor-not-allowed" : "bg-[#3B82F6] animate-pulse"}`} disabled={isSpinning}>
              {isSpinning ? "SPINNING..." : "SPIN"}
            </button>
          ) : (
            <button onClick={onClose} className={`mt-8 text-white font-bold py-2 px-14 rounded-full ${isSpinning ? "bg-gray-500 cursor-not-allowed" : "bg-[#3B82F6] animate-pulse"}`} disabled={isSpinning}>
              CLOSE
            </button>
          )}
        </div>
      </div>
      <AnimatePresence>{showEyesTokenModal && <EyesTokenModal isOpen={showEyesTokenModal} onClose={() => setShowEyesTokenModal(false)} message={message} />}</AnimatePresence>
    </div>
  );
};

EyeRoll.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  disableEyeroll: PropTypes.func.isRequired,
};

export default EyeRoll;
