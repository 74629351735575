import { useAtom } from "jotai";
import useWebSocket from "react-use-websocket";
import { roshamboLastBetAtom, roshamboNewBetAtom } from "../store/Atoms";
import { useCallback, useEffect, useState } from "react";
// import { FiUser } from "react-icons/fi";
// import { PiHouse } from "react-icons/pi";

const LastHouseShot = () => {
  const [lastBets, setLastBet] = useAtom(roshamboLastBetAtom);
  const [newbet, setNewbet] = useAtom(roshamboNewBetAtom);
  // eslint-disable-next-line no-unused-vars
  const [activePlayers, setActivePlayers] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [totalSOL, setTotalSOL] = useState(0);
  const [percent, setPercent] = useState([0, 0, 0]);
  const [showMobileTooltip, setShowMobileTooltip] = useState(false);

  const sortLastBets = (lastbets) => {
    return lastbets?.sort((a, b) => {
      const numA = Number(a[0]);
      const numB = Number(b[0]);
      if (isNaN(numA) && isNaN(numB)) return 0;
      if (isNaN(numA)) return 1;
      if (isNaN(numB)) return -1;
      return numB - numA;
    });
  };

  const updateGameData = useCallback(
    (data) => {
      const sorted = sortLastBets(data.lastbets);
      setLastBet(sorted);
      setPercent([data.rock, data.paper, data.scissors, data.total]);
      setNewbet(Number(data.newbets));
      setActivePlayers(data.activePlayers || 0);
      setTotalSOL(data.totalSOL || 0);
    },
    [setLastBet, setNewbet]
  );

  // Initial data fetching
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await fetch("https://api.dragoneyes.xyz/roshambo/lastbet");
        const data = await response.json();
        updateGameData(data.data.SOL);
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    };

    fetchInitialData();
  }, [updateGameData]);

  useWebSocket("wss://api.dragoneyes.xyz:7788/roshamboLastBet", {
    onMessage: async (event) => {
      const eventData = JSON.parse(event.data);
      const data = eventData.SOL;
      updateGameData(data);
    },
    shouldReconnect: () => true,
  });

  // Desktop Header Component
  const DesktopHeader = () => (
    <div className="hidden lg:flex items-center gap-4 text-white text-sm">
      {/* <div className="flex items-center gap-2">
        <FiUser className="text-[#D9CCB8] w-5 h-5" />
        <span className="text-[#D9CCB8] font-passion">{activePlayers}</span>
        <span className="text-[#D9CCB8] font-passion">PLAYING</span>
      </div>
      <div className="text-[#FFD850] flex items-center font-passion">
        <PiHouse className="mr-1 h-5 w-5" />
        {totalSOL.toFixed(3)} SOL
      </div> */}
      <div className="font-bold font-passion group relative cursor-help">
        LAST HOUSE SHOTS
        <div className="absolute hidden group-hover:block w-48 bg-white text-black text-xs rounded-md py-2 px-3 -bottom-16 left-1/2 transform -translate-x-1/2 shadow-lg">
          These are the house&apos;s last shots in the game
          <div className="absolute -top-1 left-1/2 transform -translate-x-1/2 w-2 h-2 bg-white rotate-45"></div>
        </div>
      </div>
    </div>
  );

  // Mobile Header Component
  const MobileHeader = () => (
    <div className="lg:hidden flex items-center font-passion text-white text-[13px] leading-none font-bold text-center relative cursor-pointer" onClick={() => setShowMobileTooltip(!showMobileTooltip)}>
      LAST HOUSE SHOTS
      <div className={`absolute ${showMobileTooltip ? "block" : "hidden"} w-48 bg-white font-passion text-black text-[10px] rounded-md py-2 px-3 -bottom-16 left-[150%] transform -translate-x-1/2 shadow-lg z-50`}>
        These are the house&apos;s last shots in the game
        <div className="absolute -top-1 left-[15%] transform -translate-x-1/2 w-2 h-2 bg-white rotate-45"></div>
      </div>
    </div>
  );

  // Skeleton untuk Shots History
  const ShotsHistorySkeleton = () => (
    <div className="flex gap-1 overflow-hidden items-center animate-pulse">
      {[...Array(15)].map((_, index) => (
        <div key={index} className="w-6 h-6 lg:w-6 lg:h-6 rounded-full bg-gray-600 sm:w-5 sm:h-5" />
      ))}
    </div>
  );

  // Skeleton untuk Progress Bar
  const ProgressBarSkeleton = () => <div className="w-full h-3 lg:h-5 rounded-full bg-gray-600 animate-pulse" />;

  // Shots History Component
  const ShotsHistory = () => (
    <div className="flex gap-1 overflow-hidden items-center">
      {Array.isArray(lastBets) && lastBets.length > 0 ? (
        <>
          {/* Last Shot */}
          <div
            className={`
              w-6 h-6 lg:w-6 lg:h-6 rounded-full flex items-center justify-center
              ${Number(lastBets[0][1].houseGuess) === 1 ? "bg-[#b4b4b4] border-[#e0e0e0]" : Number(lastBets[0][1].houseGuess) === 2 ? "bg-[#24c31e] border-[#4dff45]" : "bg-[#ffc905] border-[#ffe066]"}
              animate-pulse
              transition-all duration-200
              aspect-square
              sm:w-5 sm:h-5
              border-2
              shadow-md
              font-passion
            `}
          >
            <span className="text-black font-passion font-bold text-[10px] lg:text-xs">{Number(lastBets[0][1].houseGuess) === 1 ? "R" : Number(lastBets[0][1].houseGuess) === 2 ? "P" : "S"}</span>
          </div>

          {/* Divider */}
          <div className="hidden lg:block h-4 w-[2px] bg-gray-400 mx-2 opacity-70"></div>
          <div className="lg:hidden w-[1.5px] bg-gray-400 mx-[2px] opacity-70">|</div>

          {/* Rest of shots */}
          {lastBets.slice(1, window.innerWidth < 768 ? 10 : 23).map((bet, index) => {
            const choice = Number(bet[1].houseGuess);
            const isNew = index < newbet - 1;

            return (
              <div
                key={bet[0]}
                className={`
                  w-6 h-6 lg:w-6 lg:h-6 rounded-full flex items-center justify-center
                  ${choice === 1 ? "bg-[#b4b4b4] border-[#e0e0e0]" : choice === 2 ? "bg-[#24c31e] border-[#4dff45]" : "bg-[#ffc905] border-[#ffe066]"}
                  ${isNew ? "shadow-[0_0_10px_rgba(255,255,255,0.7)]" : ""}
                  transition-all duration-200
                  aspect-square
                  sm:w-5 sm:h-5
                  border-2
                  shadow-md
                  font-passion
                `}
              >
                <span className="text-black font-passionfont-bold text-[10px] lg:text-xs">{choice === 1 ? "R" : choice === 2 ? "P" : "S"}</span>
              </div>
            );
          })}
        </>
      ) : (
        <ShotsHistorySkeleton />
      )}
    </div>
  );

  // Progress Bar Component
  const ProgressBar = () => (
    <div className="relative w-full h-full flex items-center">
      <div className="w-full">
        {Array.isArray(lastBets) && lastBets.length > 0 ? (
          <div className="flex h-full">
            <div
              className="bg-[#b4b4b4] h-full flex items-center justify-center"
              style={{
                width: `${Math.round((percent[0] / percent[3]) * 100)}%`,
              }}
            >
              <span className="text-black text-[10px] lg:text-xs">R {Math.round((percent[0] / percent[3]) * 100)}%</span>
            </div>
            <div
              className="bg-[#24c31e] h-full flex items-center justify-center"
              style={{
                width: `${Math.round((percent[1] / percent[3]) * 100)}%`,
              }}
            >
              <span className="text-white text-[10px] lg:text-xs">P {Math.round((percent[1] / percent[3]) * 100)}%</span>
            </div>
            <div
              className="bg-[#ffc905] h-full flex items-center justify-center"
              style={{
                width: `${Math.round((percent[2] / percent[3]) * 100)}%`,
              }}
            >
              <span className="text-black text-[10px] lg:text-xs">S {Math.round((percent[2] / percent[3]) * 100)}%</span>
            </div>
          </div>
        ) : (
          <ProgressBarSkeleton />
        )}
      </div>
    </div>
  );

  return (
    <div className="sticky top-0 z-20 bg-[#282828] w-full lg:px-4 px-2 py-2">
      <div className="container lg:mx-auto">
        {/* Desktop Layout */}
        <div className="hidden lg:flex items-center gap-3">
          <div className="flex items-center gap-4 min-w-fit">
            <DesktopHeader />
          </div>
          <div className="flex-grow">
            <ShotsHistory />
          </div>
          <div className="w-full">
            <ProgressBar />
          </div>
        </div>

        {/* Mobile Layout */}
        <div className="lg:hidden flex flex-col gap-2">
          <div className="flex items-center">
            {" "}
            {/* Wrapper untuk header dan shots history */}
            <MobileHeader />
            <ShotsHistory />
          </div>
          <ProgressBar />
        </div>
      </div>
    </div>
  );
};

export default LastHouseShot;
